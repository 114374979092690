export const LOGIN = '/login';
export const PROJECT = '/project';
export const SINGLE_PROJECT = '/project/:id';
export const LIBRARY = '/library';
export const LIBRARYSEARCH = '/library/search';
export const UPLOAD_ITEM = '/upload-item';
export const MY_PROFILE = '/my-profile';
export const COMPANY_INFOR = '/company-infor';


