import React from 'react';
import {
    Form,
    Radio,
  } from "antd";

class LeadTime extends React.Component {
    render(){
        return(
            <Form.Item name="leadTime" label="Lead Time">
                <Radio.Group>
                <Radio.Button value="Week">Week</Radio.Button>
                <Radio.Button value="In Stock">In Stock</Radio.Button>
                </Radio.Group>
            </Form.Item> 
        )
    }
}

export default LeadTime;