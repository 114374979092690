import React from 'react';
import './App.css';

import {BrowserRouter, Switch, Route} from "react-router-dom";

import Layout from './container/Layout/Layout';
import ManageProjects from './container/ManageProjects';
import ManageRooms from './container/ManageRooms';
import Library from './container/Library/Library';
import LibrarySearch from './container/Library/LibrarySearch';
import Profile from './container/Profile/Profile';
import CompanyInfor from './container/Profile/CompanyInfor';
import UploadItem from './container/UploadItem';
import Login from './container/Login/Login';

import * as pagePath from './router/PagePath';

import PrivateRoute from './component/PrivateRoute/PrivateRoute';


class App extends React.Component {
  render(){
    return(
      <BrowserRouter>
          <Layout>
              <Route path="/login"><Login /></Route>
              <Switch>
                <PrivateRoute exact={true} path={pagePath.PROJECT}><ManageProjects /></PrivateRoute>
                <PrivateRoute exact={true} path={pagePath.SINGLE_PROJECT}><ManageRooms /></PrivateRoute>
                <PrivateRoute path={pagePath.UPLOAD_ITEM}><UploadItem /></PrivateRoute>
                <PrivateRoute path={pagePath.MY_PROFILE}><Profile /></PrivateRoute>
                <PrivateRoute path={pagePath.COMPANY_INFOR}><CompanyInfor /></PrivateRoute>
                <PrivateRoute exact={true} path={pagePath.LIBRARY}><Library /></PrivateRoute>
                <PrivateRoute exact={true} path={pagePath.LIBRARYSEARCH}><LibrarySearch /></PrivateRoute>
              </Switch>
          </Layout>
      </BrowserRouter>
    )
  }
}

export default App;
