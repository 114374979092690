import React from 'react';
import Room from '../Rooms/Room/Room';

class Rooms extends React.PureComponent {
    render() {
        const rooms = this.props.rooms;
        
        return (
            <div>
               {rooms.map(data=>{
                   return(
                        <Room key={data.id} roomId={data.id} data={data} />
                   )
               })}
            </div>
        )
    }
}

export default Rooms;
