import React from "react";
import PageHeading from "../../component/PageHeading/PageHeading";
import { connect } from "react-redux";
import "./Profile.css";
import CUDesigner from '../../component/Forms/CUDesigner/CUDesigner';
import {tokenAxios} from '../../axios/Axios';
import {message} from 'antd';



class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            errorMessage: "",
        };
    }

    onFinish = (values) => {
        const formMessage = message.loading('Is Updating...', 0);

        let params = {
            'store_name':values.storeName,
            'address': {
                'street_1': values.street1,
                'street_2': values.street2,
                'city': values.city,
                'zip': values.postcode,
                'state': values.state,
            },
            'phone': values.phone,
        }
        
        let userInfor = {
            storeName  : values.storeName,
            phone: values.phone,
            address: {
                'street_1': values.street1,
                'street_2': values.street2,
                'city': values.city,
                'zip': values.postcode,
                'state': values.state,                    
            },            
        }

        if(values.banner) {
            params['banner_id'] = values.banner[0].response.id
            userInfor['bannerUrl'] = values.banner[0].response.source_url;
        }

        if(values.logo) {
            params['gravatar_id'] = values.logo[0].response.id;
            userInfor['gravatar'] = values.logo[0].response.source_url;
        }
        
        tokenAxios.post('/wp-json/dokan/v1/stores/'+this.props.userInfor.id, params).then(res => {
            this.props.setUserInfor({
                ...this.props.userInfor,
                ...userInfor
            });

            formMessage();
            message.success('Done', 3);
        });
    }

    render() {
        return (
            <div className="user-info-wrap">
                <PageHeading title="User Information" />
                <CUDesigner onFinish = {(form) => {this.onFinish(form)}} userInfor={this.props.userInfor}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfor: state.UserReducer.userInfor,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUserInfor: (userInfor) => dispatch({type: 'SET_USER_INFOR', userInfor})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
