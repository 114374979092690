import React from 'react';
import { Form, Row, Col, Button, Checkbox} from 'antd';
import AntdForm from '../../../hoc/AntdForm';

import RoomRadios from '../FormElement/RoomCheckBox/RoomCheckBox';
import UploadDragger from '../FormElement/UploadDragger/UploadDragger';
import ItemMetaFields from '../FormElement/ItemMetaFields/ItemMetaFields';



class CUItem extends React.Component {

    onFinish = (values) => {
        this.props.onFinish(values, this.props.form);
    }

    render() {

        return(
            <div className='cu-item-form'>
                    {/* Form */}
                    <Form
                        form={this.props.form}
                        name="validate_other"
                        onFinish={this.onFinish}
                        onFieldsChange={this.handleFieldsChange}
                        layout="vertical"
                    >
                        <Row gutter={[12, 12]}>
                            {/* Upload Field */}
                            <Col xs={24}>
                                <UploadDragger />
                            </Col>

                            <ItemMetaFields itemType={this.props.terms} colSize={{xs:12}} />

                            {/* Save To Project */}
                            <Col md={12}>
                                <Form.Item name="rooms" label="Save To">
                                    <Checkbox.Group>
                                        {this.props.projects.map(project=>{
                                            return <RoomRadios key={project.id} rooms={this.props.rooms} projectId={project.id} projectTitle={project.title} />
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            
                            <Col xs={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                </Form.Item>
                            </Col>                                                                                                                   
                        </Row>       
                    </Form>
            </div>
        )
    }
}

export default AntdForm(CUItem);