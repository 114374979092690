export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_AUTHENNICATION = 'SET_AUTHENNICATION';
export const SET_USER_INFOR = 'SET_USER_INFOR';

//Project
export const LOAD_PROJECT = 'LOAD_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const UPDATE_PROJECT = 'EDIT_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

//Single Project
export const SET_PROJECT = 'SET_PROJECT';
export const SET_ROOMS = 'SET_ROOMS';
export const SET_ITEMS = 'SET_ITEMS';
export const SET_ITEM_TYPE = 'SET_ITEM_TYPE';
export const ADD_ROOM  = 'ADD_ROOM';
export const SET_MODAL_STATE = 'SET_MODAL_STATE';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const DELETE_ROOM = 'DELETE_ROOM';
export const SET_MODAL_ITEM_STATE = 'SET_MODAL_ITEM_STATE'
export const SET_MODAL_FORM_COMPONENT = 'SET_MODAL_FORM_COMPONENT';
export const UPDATE_ITEM_ROOMS = 'UPDATE_ITEM_ROOMS';
export const UPDATE_ITEM_GALLERY = 'UPDATE_ITEM_GALLERY';
export const UPDATE_ITEM = 'UPDATE_ITEM';


//Item
export const DELETE_ITEM = 'DELETE_ITEM';

