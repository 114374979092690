import React from 'react';

import {Link} from "react-router-dom";
import {SettingOutlined, UserOutlined, ProjectOutlined, PlusSquareOutlined, HddOutlined } from '@ant-design/icons';
import {Col, Menu, Avatar } from 'antd';
const { SubMenu } = Menu;

class LoggedMenu extends React.Component {
    render(){
        return(
            <React.Fragment>
                <Col span={12}>
                    <Menu mode='horizontal'  >
                        <Menu.Item icon={<ProjectOutlined />}><Link to="/project">Project</Link></Menu.Item>
                        <Menu.Item icon={<HddOutlined />}><Link to="/library">Library</Link></Menu.Item>
                        <Menu.Item icon={<PlusSquareOutlined />}><Link to="/upload-item">Upload Item</Link></Menu.Item>
                        <Menu.Item icon={<PlusSquareOutlined />}> 
                            <a href={`${process.env.REACT_APP_API_URL}/dashboard/portfolios`}>Portfolio</a>
                        </Menu.Item>
                    </Menu>
                </Col>
                <Col span={8} className='top-menu-right'>
                    <Menu mode='horizontal' >
                        <SubMenu title={
                            <>
                                <span>{`Hello, ${this.props.userInfor.displayName}`}</span>
                                <Avatar 
                                    size='large' 
                                    icon={<UserOutlined />}
                                    src={this.props.userInfor.gravatar}
                                    style={{'marginLeft':'15px'}}
                                />
                            </>
                            } forceSubMenuRender="true">
                        </SubMenu>
                    </Menu> 
                        </Col>
            </React.Fragment>
        )
    }
}

export default LoggedMenu;