import React from 'react';
import { Descriptions } from 'antd';

class ItemMeta extends React.Component {
    render() {
        const data = this.props.data;
        return(
            <Descriptions size='small' layout="vertical" bordered>
                <Descriptions.Item span={3} label="Notes">{data.acf.notes}</Descriptions.Item>
                <Descriptions.Item label="Price">{data.acf.price}</Descriptions.Item>
                <Descriptions.Item label="Estimate Min Price">{data.acf.est_min}</Descriptions.Item>
                <Descriptions.Item label="Estimate Max Price">{data.acf.est_max}</Descriptions.Item>
                <Descriptions.Item label="Lead time">{data.acf.leadtime}</Descriptions.Item>
                <Descriptions.Item label="Price type">{data.acf.price_type}</Descriptions.Item>
                <Descriptions.Item label="URL"><a rel="noopener noreferrer"  target="_blank" href={data.acf.url}>{data.acf.url}</a></Descriptions.Item>
            </Descriptions>
        )
    }
}

export default ItemMeta;