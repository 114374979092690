import React from 'react';
import ItemGallery from '../Item/ItemGallery';
import ItemMeta from '../Item/ItemMeta';
import {Row, Col} from 'antd';


class ItemDetails extends React.Component {
    render(){
        const data = this.props.item;
        return(
            <Row gutter={[24, 24]}>
                <Col xs={24}><ItemGallery gallery={data.acf.gallery} /></Col>
                <Col xs={24}><ItemMeta data ={data} /></Col>
            </Row>
        )
    }
}

export default ItemDetails;