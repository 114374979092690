
import React from 'react';
import {Form} from 'antd';

function AntdForm(Component) {
    return (props) => {
        const [form] = Form.useForm();
        return (<Component {...props} form={form} />)
    }
}

export default AntdForm;