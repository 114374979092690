import axios from 'axios';

const urlAccessToken = window.location.href.match(/access_token=([^&]*)/);

if( urlAccessToken ) {
    localStorage.clear();
}

function getToken() {
    const dt_user = JSON.parse(localStorage.getItem('dt_user'));
    const token = dt_user ? dt_user.token : '';
    return token;
}

const tokenAxios = axios.create({baseURL: process.env.REACT_APP_API_URL});

// Add token to axios
tokenAxios.interceptors.request.use(function (config) {
    const token = getToken();

    if( token ) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export {tokenAxios}