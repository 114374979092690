import React from 'react';
import {message} from 'antd';
import { connect } from 'react-redux';
import {tokenAxios} from '../axios/Axios';
import * as actionTypes from '../store/Actions';


const withHandleItem = (Component) => {
    class HandleItem extends React.Component {

        showModalItem = (item) => {
            this.props.setModalItemState({...this.props.itemModal, visible: true, currentItem:item})
        }

        cancelItemModal = () => {
            this.props.setModalItemState({...this.props.itemModal,visible: false})
        }

        cancelFormModal = () => {
            this.props.setModalFormstate({...this.props.modalFormState, visible: false}) 
        }

        handleEditDetails(fdata, formMessage) {
            const itemId = this.props.modalFormState.args.id;
            tokenAxios.post('/wp-json/wp/v2/item/'+itemId,{
                author: this.props.userInfor.id,
                title: fdata.name,
                content: fdata.desciptions,
                "item-type": fdata.category,
                fields : {
                    notes : fdata.notes,
                    est_min : fdata.estMin,
                    est_max: fdata.estMax,
                    url : fdata.url,
                    price : fdata.price,
                    leadtime : fdata.leadTime,
                    room : fdata.rooms,
                    price_type : fdata.priceType,
                    tag: fdata.tag
                }
            }).then(res => {
                this.props.updateItem('all', res.data);
                formMessage();
                message.success('Done', 3);
                this.cancelFormModal();
            });
        }
    
        handleUploadImage(values, formMessage) {        
            if( !values.dragger ) {
                formMessage();
                this.cancelFormModal();
                return;
            }

            const itemId = this.props.modalFormState.args.id;
            const dragger = values.dragger ? values.dragger : [];
            const imageIds = dragger.map( item => {
                return item.response.id;
            });
    
            tokenAxios.put('/wp-json/wp/v2/item/'+itemId,{
                fields : {
                    gallery : imageIds,
                }
            }).then(res=>{
                this.props.updateItem('gallery', res.data);
                formMessage();
                message.success('Done, Updated gallery images', 3);
                this.cancelFormModal();
            });    
    
        }
    
        handleAssignRoom(values, formMessage) {
            const itemId = this.props.modalFormState.args.id;
            tokenAxios.put('/wp-json/wp/v2/item/'+itemId,{
                "fields" : {
                    "room": values.rooms
                }                
            })
            .then(res => {
                this.props.updateItem('room', res.data);
                formMessage();
                message.success('Done', 3);
                this.cancelFormModal();
            });
        }
 
        removeItemRooms = (item, roomId) => {
            const loadingMessage = message.loading('Removing...', 0);
            const roomAssigned = item.acf.room;
            const newRoom = roomAssigned.filter(room =>{
                return room !== roomId
            });
            tokenAxios.put('/wp-json/wp/v2/item/'+item.id,{
                "fields" : {
                    "room": newRoom
                }                
            })
            .then(res => {
                this.props.updateItem('room', res.data);
                loadingMessage();
                message.success('Removed', 3);
            });
        }
        
        deleteItem = (item) => {
            const loadingMessage = message.loading('Removing...', 0);
            
            tokenAxios.delete('/wp-json/wp/v2/item/'+item.id,{
                id: item.id,
                force: true,
            }).then(res => {
                loadingMessage();
                message.success('Removed', 3);
                this.props.r_delete_item(res.data.id);
            }); 

        }

        render() {
            return(
                <Component
                    // List Property 
                    userInfor = {this.props.userInfor}
                    projects  = {this.props.projects}
                    rooms = {this.props.rooms}
                    items = {this.props.items}
                    itemType = {this.props.itemType}

                    //List Method
                    itemModal = {this.props.itemModal}

                    modalFormState = {this.props.modalFormState}
                    modalFormComponent = {this.props.modalFormComponent}

                    showModalItem = {(item) => this.showModalItem(item)}
                    setModalFormstate = {this.props.setModalFormstate}
                    setModalFormComponent = {this.props.setModalFormComponent}

                    setItems = {this.props.setItems}
                    setProjects = {this.props.setProjects}
                    setRooms = {this.props.setRooms}
                    setItemType = {this.props.setItemType}

                    cancelItemModal = {this.cancelItemModal}
                    cancelFormModal = {this.cancelFormModal}
                    handleAssignRoom = {(fdata, fmessage) => this.handleAssignRoom(fdata, fmessage)}
                    handleUploadImage = {(fdata, fmessage) => this.handleUploadImage(fdata, fmessage)}
                    handleEditDetails = {(fdata, fmessage) => this.handleEditDetails(fdata, fmessage)}
                    removeItemRooms = {(item, roomId) => this.removeItemRooms(item, roomId) }
                    deleteItem = {(item) => this.deleteItem(item) }
                    {...this.props}
                />
            )
        }
    }
    
    const mapStateToProps = state => {
        return {
            'userInfor' : state.UserReducer.userInfor,
            'items' : state.singleProjectReducer.items,
            'itemType' : state.singleProjectReducer.itemType,
            'projects' : state.singleProjectReducer.projects,
            'rooms' : state.singleProjectReducer.rooms,
            'modalFormState' : state.singleProjectReducer.modalFormState,
            'itemModal' : state.singleProjectReducer.itemModal,
            'modalFormComponent' : state.singleProjectReducer.modalFormComponent
        }
    }
    
    const mapDispatchToProps = dispatch => {
        return {
            setProjects: (projects) =>  {dispatch({type: actionTypes.SET_PROJECT, projects})},

            setRooms: (rooms) => {dispatch({type: actionTypes.SET_ROOMS, rooms})},

            setItems: (items) => {dispatch({type: actionTypes.SET_ITEMS, items})},
            setItemType: (itemType) => {dispatch({type: actionTypes.SET_ITEM_TYPE, itemType})},
            updateItem: (which, item) => {dispatch({type: actionTypes.UPDATE_ITEM, which, item})},
            r_delete_item: (itemId) => {dispatch({type: actionTypes.DELETE_ITEM, itemId})},

            setModalFormstate: (modalState) => {dispatch({type: actionTypes.SET_MODAL_STATE, modalState})},
            setModalItemState: (modalItemState) => {dispatch({type: actionTypes.SET_MODAL_ITEM_STATE, modalItemState})},
            setModalFormComponent: (component) => {dispatch({type: actionTypes.SET_MODAL_FORM_COMPONENT, component})},
        }
    };

    return connect(mapStateToProps, mapDispatchToProps)(HandleItem);

}


export default withHandleItem;