import React from 'react';
import PageHeading from '../component/PageHeading/PageHeading';
import PageControls from '../component/PageControls/PageControls';
import CUForm from '../component/Project/CUForm/CUForm';
import { connect } from 'react-redux';

import {tokenAxios} from '../axios/Axios';

import * as actionTypes from '../store/Actions';

import {Spin, Button} from 'antd';

import { PlusCircleTwoTone } from '@ant-design/icons';


import ProjectListing from '../component/Project/ProjectListing/ProjectListing';

class ManageProjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            errorMessage : '',
            modalFormState: {
                visible : false,
                title : '',
                desc : '',
                type: '',
                confirmLoading: false,
            },
            formData: {
                id: '',
                title: '',
                desc: ''
            }
        } 
    }

    handleCreateProject = () => {
        this.setState({
            modalFormState : {
                visible : true,
                title : 'Add Project',
                desc: 'Create a project to organize your designs!',
                type: 'add'
            },
            formData: {
                id: '',
                title: '',
                desc: ''
            }
        })
    }

    handleEditProject = (postData) => {
        this.setState({
            modalFormState : {
                visible : true,
                title : 'Edit Project',
                type: 'edit',
                desc: 'Edit the name and description of your Project Collection below.'
            },
            formData: {
                'id' : postData.id,
                'title' : postData.title,
                'desc' : postData.desc
            }
        });

    }

    handleCancelModal = () => {
        this.setState({
            modalFormState : {
                visible : false,
            }
        });         
    }

    handleForm = (form) => {
        //Modal Loading
        form.validateFields().then(values => {
            //Modal Loading
            this.setState({
                modalFormState: Object.assign(this.state.modalFormState, {confirmLoading: true}),
                formData: {
                    id: this.state.formData.id,
                    title: values.title,
                    desc: values.desc,
                },
            });

            if( this.state.modalFormState.type === 'add' ) {
                tokenAxios.post('/wp-json/wp/v2/project/',{
                    author: this.props.userInfor.id,
                    title: values.title,
                    content: values.desc,
                    status: 'publish'
                }).then(res => {
                    this.props.addProject(res.data);
                    this.setState({modalFormState: Object.assign(this.state.modalFormState, {confirmLoading: false, visible: false})});
                });
            } else if(this.state.modalFormState.type === 'edit') {
                tokenAxios.post('/wp-json/wp/v2/project/'+this.state.formData.id,{
                    id: this.state.formData.id,
                    title: values.title,
                    content: values.desc,
                }).then(res => {
                    this.props.updateProject(res.data);
                    this.setState({modalFormState: Object.assign(this.state.modalFormState, {confirmLoading: false, visible: false})});
                });                
            }

        }) 
    }

    handleDeleteProject = (id) => {
        tokenAxios.delete('/wp-json/wp/v2/project/'+id,{
            id: id,
            force: true,
        }).then(res => {
            this.props.deleteProject(res.data.id);
        });  
    }

    componentDidMount() {
        this.setState({isLoading: true});
        tokenAxios.get('/wp-json/wp/v2/project/',{
            params: {
                author: this.props.userInfor.id,
                per_page: 25
            }
        })
        .then(res => {
            this.props.loadProject(res.data);        
            this.setState({isLoading: false})
        });
    }
    
    render() {
        return(
            <div>
                <Spin tip="Loading My Projects..." spinning={this.state.isLoading}>
                    <PageHeading title={`${this.props.userInfor.displayName} Project`}/>
                    <PageControls>
                        <Button onClick={this.handleCreateProject} type="primary" icon={<PlusCircleTwoTone />}>Create new</Button>
                    </PageControls>
                    <CUForm data={this.state.formData} handleForm={(form) => this.handleForm(form)} modalFormState={this.state.modalFormState} cancelModal={this.handleCancelModal} />
                    <ProjectListing deleteProject = {(id) => this.handleDeleteProject(id)} editProject={(postData) => this.handleEditProject(postData)} projects={this.props.projects} />
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        'userInfor' : state.UserReducer.userInfor,
        'projects'  : state.projectReducer.projects
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadProject: (projects) => dispatch({type: actionTypes.LOAD_PROJECT, projects}),
        addProject: (project) => dispatch({type: actionTypes.ADD_PROJECT, project}),
        updateProject: (project) => {dispatch({type: actionTypes.UPDATE_PROJECT, project})},
        deleteProject: (id) => {dispatch({type: actionTypes.DELETE_PROJECT, id})}
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageProjects);