import React from 'react';

import { connect } from 'react-redux';
import {Route, Redirect} from "react-router-dom";


class PrivateRoute extends React.Component {
    render() {
        return (
            <Route path={this.props.path} >
                {this.props.isAuthenication ? this.props.children : <Redirect to={{ pathname: "/login", state: { from: this.props.location },}} />}
            </Route>
        )
    }
}


const mapStateToProps = state => {
    return {
        'isAuthenication' : state.UserReducer.isAuthenication,
    }
}

export default connect(mapStateToProps, null)(PrivateRoute);