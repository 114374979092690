import React from 'react';
import './PageControls.css';

class PageControls extends React.Component{
    render() {
        return(
            <div className='page-controls'>{this.props.children}</div>
        )
    }
}

export default PageControls;