import React from 'react';
import './ItemTypeGridImg.css';

class ItemTypeGridImg extends React.Component {
    render() {
        return (
            <div className='wrap-item-type-imgs'>
                {this.props.itemImages.map(function(imgUrl, i){
                    return <div className='item-type-img' key={i}><img src={imgUrl} alt={imgUrl}/></div>;
                })}
            </div>
        );
    }
}

export default ItemTypeGridImg;