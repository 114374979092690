import React from "react";

import {
  Form,
  Input,
  Row,
  Col,
  Radio,
  InputNumber,
} from "antd";

import ItemTypeSelect from "./ItemTypeSelect/ItemTypeSelect";
import LeadTime from '../LeadTime/LeadTime';

class ItemMetaFields extends React.Component {
  render() {


    return (
      <React.Fragment>
        {/* Select product type */}
        <Col {...this.props.colSize}>
              <ItemTypeSelect itemType={this.props.itemType} required={true}/>
        </Col>

        {/* Item Tag */}
        <Col {...this.props.colSize}>
          <Form.Item label="Tags">
            <Form.Item name="tag" noStyle>
              <Input placeholder="Enter Tags, Separated by Commas" />
            </Form.Item>
          </Form.Item>
        </Col>

        {/* Item Desc */}
        <Col {...this.props.colSize}>
          <Form.Item label="Descriptions">
            <Form.Item name="desciptions" noStyle>
              <Input placeholder="Enter Description" />
            </Form.Item>
          </Form.Item>
        </Col>

        {/* Item Name */}
        <Col {...this.props.colSize}>
          <Form.Item label="Name" required>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter item name!",
                },
              ]}
              noStyle
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Form.Item>
        </Col>

        {/* Item URL */}
        <Col {...this.props.colSize}>
          <Form.Item label="URL">
            <Form.Item name="url" noStyle>
              <Input placeholder="Enter URL" />
            </Form.Item>
          </Form.Item>
        </Col>

        <Col {...this.props.colSize}>
          <Row gutter={[12, 12]}>
              {/* Item Price */}
              <Col {...this.props.colSize}>
                <Form.Item label="Price">
                  <Form.Item name="price" noStyle>
                    <InputNumber placeholder="Enter Price" />
                  </Form.Item>
                </Form.Item>
              </Col>

              {/* Item Price type */}
              <Col {...this.props.colSize}>
                <Form.Item name="priceType" label="Price Type">
                  <Radio.Group>
                    <Radio.Button value="DNET">DNET</Radio.Button>
                    <Radio.Button value="Retail">Retail</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
        </Col> 

        {/* Lead Time */}
        <Col {...this.props.colSize}>

          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <LeadTime />
            </Col> 
                      
            <Col {...this.props.colSize}>
              <Form.Item label="Estimated Min">
                <Form.Item name="estMin" noStyle>
                  <InputNumber />
                </Form.Item>
              </Form.Item>
            </Col>

            <Col {...this.props.colSize}>
              <Form.Item label="Estimated Max">
                <Form.Item name="estMax" noStyle>
                  <InputNumber />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        {/* Item Price type */}
        <Col {...this.props.colSize}>
          <Form.Item name="notes" label="Notes">
            <Input.TextArea
              placeholder="Enter Notes"
              style={{ minHeight: "118px" }}
            />
          </Form.Item>
        </Col>
      </React.Fragment>
    );
  }
}

export default ItemMetaFields;
