import React from 'react';
import { Row, Form } from "antd";
import ItemMetaFields from '../../FormElement/ItemMetaFields/ItemMetaFields';
import './EditItemDetails.css';

class EditItemDetails extends React.PureComponent {
    render() {
        const item = this.props.item;
        this.props.form.resetFields();
        this.props.form.setFieldsValue({
            desciptions: item.content.rendered.replace(/(<([^>]+)>)/ig,""),
            name: item.title,
            url: item.acf.url,
            price: item.acf.price,
            priceType : item.acf.price_type,
            leadTime : item.acf.leadtime,
            estMin: item.acf.est_min,
            estMax: item.acf.est_max,
            notes:  item.acf.notes,
            tag: item.acf.tag,
            category: item.itemType.slice().reverse()
        });

        
        return(
			<Form className="compact-edit-form" form={this.props.form} layout="layout" labelCol={{span: 6}} size="small" >
				<Row gutter={[12, 12]}>
                    <ItemMetaFields itemType={this.props.itemType} colSize={{xs:24}} />
				</Row>
			</Form>
        )
    }
}

export default EditItemDetails;