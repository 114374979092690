import React from 'react';
import AntdForm from '../../../hoc/AntdForm';
import {
    Form,
    Input,
    Button,
  } from 'antd';

import Uploader from './Uploader/Uploader';
import State from './State/State';

const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 10,
      },
    },
  };

class CUDesigner extends React.PureComponent {
    onFinish = (values) => {
        this.props.onFinish(values);
    }

    render() {
        this.props.form.setFieldsValue({
            email: this.props.userInfor.email,
            phone: this.props.userInfor.phone,
            storeName: this.props.userInfor.storeName,
            street1: this.props.userInfor.address.street_1,
            street2: this.props.userInfor.address.street_2,
            city:this.props.userInfor.address.city,
            postcode:this.props.userInfor.address.zip,
            state: this.props.userInfor.address.state
        });

        return(
            <Form
            {...formItemLayout}
            form={this.props.form}
            name="updateUserInfor"
            onFinish={this.onFinish}
        >
            <Uploader name='logo' label='Logo' imageUrl={this.props.userInfor.gravatar} />
            <Uploader name='banner' label='Banner' imageUrl={this.props.userInfor.bannerUrl} />
            <Form.Item
                name="storeName"
                label="Store Name"
                rules={[
                    {
                        required: true,
                        message: "Please input your store name!",
                    },
                ]}
                hasFeedback
            >
                <Input />
            </Form.Item>

            <Form.Item
                disabled={true}
                name="street1"
                label="Street 1"
                rules={[
                    {
                        required: true,
                        message: "Please input your street!",
                    },
                ]}
                hasFeedback
            >
                <Input />
            </Form.Item>

            <Form.Item
                disabled={true}
                name="street2"
                label="Street 2"
            >
                <Input />
            </Form.Item>

            <Form.Item
                disabled={true}
                name="city"
                label="City"
            >
                <Input />
            </Form.Item>

            <Form.Item
                disabled={true}
                name="postcode"
                label="Post/Zip code"
            >
                <Input />
            </Form.Item>

            <State />          


            <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                    {
                        message: "Please input your phone number!",
                    },
                ]}
            >
                <Input
                    //addonBefore={prefixSelector}
                    style={{
                        width: "100%",
                    }}
                />
            </Form.Item>
                
            {/* 
            <Form.Item
                name="website"
                label="Website"
                rules={[
                    {
                        message: "Please input website!",
                    },
                ]}
            >
                <AutoComplete
                    //options={websiteOptions}
                    //onChange={onWebsiteChange}
                    placeholder="website"
                >
                    <Input />
                </AutoComplete>
            </Form.Item>
            */}
            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">Update Informations</Button>
            </Form.Item>
        </Form>
        )
    }
}

export default AntdForm(CUDesigner);