import React from "react";

import { Row, Col, Card, Popconfirm } from "antd";
import {  EditOutlined, EllipsisOutlined, DeleteOutlined  } from "@ant-design/icons";
import {Link} from "react-router-dom";
import * as pagePath from '../../../router/PagePath';

class ProjectListing extends React.Component {
  render() {
    return (
      <Row gutter={[24, 24]}>
        {
          this.props.projects.map(data => {
              const desc = data.content.rendered.replace(/(<([^>]+)>)/ig,"");
              return(
                  <Col key={data.id} md={6}>
                    <Card title={data.title} 
                        extra={<Link 
                          to={{
                            pathname: pagePath.PROJECT + '/' + data.id,
                            state: { fromDashboard: true }
                          }}>Manage Project</Link>}
                        actions={[
                            <Popconfirm
                              title="Are you sure delete this project?"
                              onConfirm={() => this.props.deleteProject(data.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <DeleteOutlined key="delete" />
                            </Popconfirm>                          
                          ,
                          <EditOutlined onClick={() => this.props.editProject({
                            id:data.id,
                            title:data.title,
                            desc
                          })} key="edit" />,
                          <EllipsisOutlined key="ellipsis" />,
                        ]}
                      >
                    {desc} 
                  </Card>
                </Col>
              )
          })
        }                     
      </Row>
    );
  }
}

export default ProjectListing;
