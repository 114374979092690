import * as actionTypes from './Actions';


const initialState = {
    'userToken' : '',
    'isAuthenication' : false,
    'userInfor': {}
}

const UserReducer = (state = initialState, actions) => {
    switch(actions.type) {
        case actionTypes.SET_USER_TOKEN:
            return {
                ...state,
                userToken : actions.token
        }
        case actionTypes.SET_AUTHENNICATION:
            return {
                ...state,
                isAuthenication: actions.authn
        }
        case actionTypes.SET_USER_INFOR:
            return {
                ...state,
                userInfor: actions.userInfor
            }    
        default: return state;
    }
}

export default UserReducer;