import React from 'react';
import { Layout as AntdLayout, Spin} from 'antd';
import './Layout.css';
import Aux from '../../hoc/Aux'
import { connect } from 'react-redux';

import TopMenu from '../../component/Navigation/TopMenu/TopMenu';
import BotMenu from '../../component/Navigation/BotMenu/BotMenu';
import Container from '../../hoc/Container';

const { Header, Footer, Content } = AntdLayout;

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        } 
    }

    componentWillMount() {
      // Loading User datals
      const dt_user = JSON.parse(localStorage.getItem('dt_user'));
      if(dt_user) { // If token exist locacate in localStorage
        this.props.setUserInfor({
          id : dt_user.id,
          email : dt_user.user_email,
          displayName : dt_user.user_display_name,
          storeName  : dt_user.store_name,
          phone: dt_user.phone,
          address: dt_user.address,
          bannerUrl: dt_user.banner_url,
          gravatar:dt_user.gravatar,
          state:dt_user.state
        });
        this.props.setToken(dt_user.token);
        this.props.setAuthenication(true);
      }

    }

    render(){
      return(
        <Aux>
            <Spin tip="Loading..." size='large' spinning={this.state.isLoading}>
              <AntdLayout>
                <Header>
                  <Container><TopMenu/></Container>
                </Header>
                <Content><Container>{this.props.children}</Container></Content>
                <Footer><Container><BotMenu/></Container></Footer>
              </AntdLayout>
            </Spin>
        </Aux>
      )
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        setToken: (token) => {dispatch({type: 'SET_USER_TOKEN', token})},
        setAuthenication: (authn) => dispatch({type: 'SET_AUTHENNICATION', authn}),
        setUserInfor: (userInfor) => dispatch({type: 'SET_USER_INFOR', userInfor})
    }
};


const mapStateToProps = state => {
  return {
      'isAuthenication' : state.UserReducer.isAuthenication,
      'userToken' : state.UserReducer.userToken,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);