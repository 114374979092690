import React from 'react';

import {Row, Col} from 'antd';

class BotMenu extends React.Component {
    render() {
        return(
            <Row>
                <Col md={24}>DesignTrade Design Corp. ©2020</Col>
            </Row>

        );
    }
}

export default BotMenu;