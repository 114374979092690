import React from 'react';
import AntdForm from '../../../hoc/AntdForm';
import {
    Form,
    Slider,
    Button
  } from 'antd';

import ItemTypeSelect from "../FormElement/ItemMetaFields/ItemTypeSelect/ItemTypeSelect";
import LeadTime from "../FormElement/LeadTime/LeadTime";

class FilterForm extends React.Component {
    render() {
        return(
            <Form
                form={this.props.form}
                name="filterItem"
                onFinish={this.props.onFilterChange}
                layout="vertical"
                onValuesChange={this.props.onFilterChange}
            >
                <ItemTypeSelect form={this.props.form} itemType={this.props.itemType} required={false} />

                <Form.Item
                    name="rangePrice"
                    label="Price"
                >
                    <Slider marks = {{
                            0: '$0',
                            500: '$500'
                        }}    
                            min={0} 
                            max={500} 
                            range 
                            defaultValue={[0, 500]} 
                    />

                </Form.Item>

                <LeadTime />

                <Form.Item>
                    <Button type="primary" htmlType="submit">Search</Button>
                </Form.Item>               
            </Form>
        )
    }
}

export default AntdForm(FilterForm);