import React from 'react';

class CompanyInfor extends React.Component {
    render() {
        return(
            <div>Company Infor</div>
        )
    }
}

export default CompanyInfor;