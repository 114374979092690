import React from 'react';

import {Link} from "react-router-dom";
import {Col, Menu} from 'antd';


class LogOutMenu extends React.Component {
    render(){
        return(
            <React.Fragment>
                <Col span={12}>
                <Menu mode='horizontal'>
                    <Menu.Item><Link to="/">PLATFORM</Link></Menu.Item>
                    <Menu.Item><Link to="/">SUPPORT</Link></Menu.Item>
                    <Menu.Item><Link to="/">ABOUT</Link></Menu.Item>
                </Menu>
                </Col>
            </React.Fragment>
        )
    }
}

export default LogOutMenu;