import React from 'react';
import { Form, Input, Modal} from 'antd';
import antdForm from '../../../hoc/AntdForm';


class CUForm extends React.Component {
    render() {
        const form = this.props.form;
        form.setFieldsValue({
            title:this.props.data.title,
            desc:this.props.data.desc,
            id:this.props.data.id
        });
        return(
            <div>
                <Modal
                    title={this.props.modalFormState.title}
                    visible={this.props.modalFormState.visible}
                    onOk={() => {this.props.handleForm(form)}}
                    confirmLoading={this.props.modalFormState.confirmLoading}
                    onCancel={this.props.cancelModal}
                    centered='true'
                >
                <p>{this.props.modalFormState.desc}</p>
                  <Form form={form} labelAlign='left' labelCol={{span: 5}}>
                      <Form.Item 
                          name="title"
                          label="Title"
                          rules={[
                            {
                              required: true,
                              message: 'Please input the title of collection!',
                            },
                          ]}
                        >
                      <Input />
                      </Form.Item>
                      <Form.Item label="Description" name="desc">
                      <Input/>
                      </Form.Item>
                  </Form>                    
                </Modal>
            </div>

        )
    }
}

export default antdForm(CUForm);