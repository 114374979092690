import React from 'react';
import { Row, Col, Card} from "antd";
import {Link} from "react-router-dom";
import * as pagePath from '../../../router/PagePath';
import ItemTypeGridImg from '../ItemTypeGridImg/ItemTypeGridImg';
const {Meta} = Card;


class ItemTypeListing extends React.Component {
    render() {

        const itemType = this.props.itemType.filter( item => {
            return item.parent === 0;
        });

        return(
            <div className='item-listing'>
                <Row gutter={[12, 12]} justify='center'>
                    {itemType.map(item => {
                        const query = {
                            'item-type':item.id
                        }
                        const qrString = new URLSearchParams(query).toString();

                        return(
                            <Col key={item.id} xs={12} md={6} xxl={6} >
                                <Link
                                to={{
                                    pathname: pagePath.LIBRARYSEARCH,
                                    search: `?${qrString}`,
                                    query
                                }}
                                >
                                <Card
                                    hoverable
                                    cover={<div><ItemTypeGridImg itemImages={item.item_images} /></div>}
                                    style={{
                                        height:'100%',
                                        display:'flex',
                                        flexDirection:'column',
                                        justifyContent:'center'
                                    }}
                                   >
                                    <Meta
                                        title={unescape(item.name)}
                                        description={item.count_posts_by_author}
                                        style={{
                                            textAlign:'center'
                                        }}
                                    />
                                </Card>                                     
                                </Link>                                                              
                            </Col>
                        );
                    })}
                </Row>
            </div>
        )
    }
}

export default ItemTypeListing;