import React from 'react';
// import redux
import { connect } from 'react-redux';
// import axios
import axios from 'axios';

//import LoginForm
import LoginForm from './LoginForm/LoginForm';
import './Login.css';

// Router dom
import {Redirect} from "react-router-dom";

// Antd
import {Spin} from 'antd';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogging: false,
            isPageLoading: false,
            tip: 'Loading...',
            errorMessage : ''
        } 
    }

    componentWillMount() { 
        const urlAccessToken = window.location.href.match(/access_token=([^&]*)/);
        if (urlAccessToken) {
            this.setState({isPageLoading:true});
            axios.post(process.env.REACT_APP_API_URL+'/wp-json/jwt-auth/v1/token/user', {}, {
              headers: {"Authorization" : `Bearer ${urlAccessToken[1]}`}
            }).then((res) => {
                res.data.token = urlAccessToken[1];
                this.props.setUserInfor({
                    id : res.data.id,
                    email : res.data.user_email,
                    displayName : res.data.user_display_name,
                    storeName  : res.data.store_name,
                    phone: res.data.phone,
                    address: res.data.address,
                    bannerUrl: res.data.banner_url,
                    gravatar:res.data.gravatar,
                    state:res.data.state
                });
  
                //Local state
                this.setState({
                    isPageLoading:true,
                });

                localStorage.setItem('dt_user', JSON.stringify(res.data));

                this.props.setToken(urlAccessToken[1]);
                this.props.setAuthenication(true);
            });
        }

    }


    handleLogin = values => {
        this.setState({isLogging:true});

        axios.post(process.env.REACT_APP_API_URL+`/wp-json/jwt-auth/v1/token`, {"username":values.username,"password":values.password }).then(res => {
            //Redux state

            this.props.setUserInfor({
                id : res.data.id,
                email : res.data.user_email,
                displayName : res.data.user_display_name,
                storeName  : res.data.store_name,
                phone: res.data.phone,
                address: res.data.address,
                bannerUrl: res.data.banner_url,
                gravatar:res.data.gravatar,
                state:res.data.state
            });

            //Local state
            this.setState({
                isLogging:false,
                errorMessage:''
            });

            localStorage.setItem('dt_user', JSON.stringify(res.data));

            this.props.setToken(res.data.token);
            this.props.setAuthenication(true);
        }).catch(error => {
            const errorCode = error.response.data.code;
            let errorMessage = '';

            if( errorCode.indexOf('incorrect_password') ) {
                errorMessage = 'Your email or password wasn’t recognized. Please try again.'
            } else {
                errorMessage = error.response.data.message;
            }
            this.setState({
                isLogging: false,
                errorMessage : errorMessage
            });
        });
    };

    render() {
        if( this.props.isAuthenication === true ) {
            return (<Redirect to={{ pathname: "/project"}} />);
        } else {
            return (
                <Spin tip={this.state.tip} size='large' spinning={this.state.isPageLoading}>
                    <LoginForm errorMessage={this.state.errorMessage} isLogging={this.state.isLogging} onSubmitLoginForm={value => this.handleLogin(value)} />
                </Spin>
            )
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setToken: (token) => {dispatch({type: 'SET_USER_TOKEN', token})},
        setAuthenication: (authn) => dispatch({type: 'SET_AUTHENNICATION', authn}),
        setUserInfor: (userInfor) => dispatch({type: 'SET_USER_INFOR', userInfor})
    }
};

const mapStateToProps = state => {
    return {
        'isAuthenication' : state.UserReducer.isAuthenication,
        'userToken' : state.UserReducer.userToken,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);