import React from 'react';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";

import { connect } from 'react-redux';
import CURoom from '../../Forms/CURoom';
import * as actionTypes from '../../../../store/Actions';

import {tokenAxios} from '../../../../axios/Axios';


class RoomHeader extends React.Component {
    handleEditRoom = () => {
        this.props.setModalFormstate({
            visible : true,
            title : 'Edit Room',
            desc : "Choose from our list of most commonly designed rooms! Use the description field to comment, and add details and notes for your team. If you don't see a room on our list, create your own.",
            type: 'edit-room',
            confirmLoading: false              
        });
        this.props.setModalFormComponent(<CURoom title={this.props.title} desc={this.props.desc} id={this.props.id} />)
    }
    
    deleteRoom = (id) => {
        tokenAxios.delete('/wp-json/wp/v2/room/'+id,{
            id: id,
            force: true,
        }).then(res => {
            this.props.deleteRoom(res.data.id);
        });  
    }

    render(){
        return(
            <div className='room-header'>
                <div className='room-title'>
                    <h2>{this.props.title}</h2>
                    <div className='room-actions'>
                        <EditOutlined onClick={this.handleEditRoom} />
                        <Popconfirm
                              title="Are you sure delete this room? Deleting the room will remove the products from this project."
                              onConfirm={() => this.deleteRoom(this.props.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                            <DeleteOutlined key='delete' />
                        </Popconfirm> 
                    </div>
                </div>
                <div className='room-desc'>{this.props.desc}</div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        'modalFormState' : state.singleProjectReducer.modalFormState
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModalFormstate: (modalState) => {dispatch({type: actionTypes.SET_MODAL_STATE, modalState})},
        deleteRoom: (id) => {dispatch({type: actionTypes.DELETE_ROOM, id})},
        setModalFormComponent: (component) => {dispatch({type: actionTypes.SET_MODAL_FORM_COMPONENT, component})}
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomHeader);

