
import React from 'react';
import { Drawer} from 'antd';
import FilterForm from '../Forms/FilterForm/FilterForm';
import {tokenAxios} from '../../axios/Axios';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/Actions';

class FilterDraw extends React.Component {

    handleFilterChange = (values, allValues) => {
        this.props.onPageLoading();

        const author = this.props.userInfor.id;

        const rooms = this.props.rooms.map((room) => {
            return room.id
        }).join(',');

        let params = {
            author,
            rooms,
            per_page: 100,
        };


        if( allValues.category ) {
            params['item-type'] = allValues.category[allValues.category.length-1]
        }

        if( allValues.rangePrice ) {
            params['minPrice'] = allValues.rangePrice[0];
            params['maxPrice'] = allValues.rangePrice[1];
        }

        if( allValues.leadTime ) {
            params['leadTime'] = allValues.leadTime
        }

        tokenAxios.get('/wp-json/wp/v2/item/',{params}).then(res => {
            this.props.setItems(res.data);
            this.props.onPageUnload();
        });
    }     

    render() {
        return(
            <div>
                <Drawer 
                    title="Filter Item"
                    placement={this.props.placement}
                    closable={true}
                    onClose={this.props.close}
                    visible={this.props.drawVisible}
                    key={this.props.placement}
                    width="320"
                >
                    <FilterForm onFilterChange={this.handleFilterChange} itemType={this.props.itemType} />
                </Drawer>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        'userInfor' : state.UserReducer.userInfor,
        'rooms' : state.singleProjectReducer.rooms,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setItems: (items) => {dispatch({type: actionTypes.SET_ITEMS, items})},
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDraw);