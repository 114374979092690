import React from 'react';
import withHandleItem from '../../hoc/WithHandleItem';
import PageHeading from '../../component/PageHeading/PageHeading';
import {tokenAxios} from '../../axios/Axios';
import {Spin, /*Button, message*/} from 'antd';
//import PageControls from '../../component/PageControls/PageControls';
import ItemTypeListing from '../../component/Item/ItemTypeListing/ItemTypeListing';

class Library extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            errorMessage : '',
        }
    }
    componentDidMount() {
        const author = this.props.userInfor.id;

        Promise.all([
            tokenAxios.get('/wp-json/wp/v2/item/',{params: {author}}),
            tokenAxios.get('/wp-json/wp/v2/item-type',{
                params: {
                    author,
                    per_page: '100',
                },
            }),
        ])
        .then(res => {
            const item = res[0].data;
            const itemType = res[1].data;

            this.props.setItems(item);
            this.props.setItemType(itemType);
            this.setState({isLoading: false})
        });
    }         
    render() {   
        return(
            <div>
                <Spin tip="Loading Item Type..." spinning={this.state.isLoading}>
                    <PageHeading title={`My Library`}/>
                    <ItemTypeListing itemType={this.props.itemType}/>
                </Spin>
            </div>
        )
    }
}

export default withHandleItem(Library);