import React from "react";
import {Card, Popconfirm} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import ItemGallery from "../Item/ItemGallery";
const {Meta} = Card;

class Item extends React.Component {
  render() {
    const data = this.props.data;

    let deleteAct = (<Popconfirm
      title="This product will be removed from this room but will remain in any other rooms it has been added to. Do you want to remove it here?"
      onConfirm={() => this.props.updateItemRooms(data)}
      okText="Yes"
      cancelText="No">
      <DeleteOutlined key="delete" />
    </Popconfirm>);

    if( this.props.delete === 'permanently-delete' ) {
      deleteAct = (<Popconfirm
        title="Are you sure you want to delete it permanently?"
        onConfirm={() => this.props.deleteItem(data)}
        okText="Yes"
        cancelText="No">
        <DeleteOutlined key="delete" />
      </Popconfirm>);
    }

    return (
      <Card
        hoverable
        cover={
          <div>
            <ItemGallery showModalItem={this.props.showModalItem} data={data} gallery={data.acf.gallery} imageSize='slide' />
          </div>
        }
        actions={[
          <EditOutlined
            onClick={() => this.props.editDetails()}
            key="edit"
          />,
          <PlusCircleOutlined
            key="Change Room"
            onClick={() => this.props.assignRooms()}
          />,
          <AppstoreAddOutlined 
            key="Add Image"
            onClick={()=> this.props.uploadImage()}  
          />,
          deleteAct,
        ]}>
        <Meta
          title={data.title}
        />
      </Card>
    );
  }
}

export default Item;
