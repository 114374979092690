import React from 'react';
import PageHeading from '../component/PageHeading/PageHeading';
import CUItem from '../component/Forms/CUItem/CUItem';
import {tokenAxios} from '../axios/Axios';
import {Spin, message} from 'antd';
import { connect } from 'react-redux';



class UploadItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            errorMessage : '',
            terms: [],
            projects: [],
            rooms: []
        } 
    }

    handleUploadItem = (fdata, form) => {
        const loadingMessage = message.loading('Saving item in progress..', 0);
        const imageIds = this.getImageIds(fdata.dragger);
        tokenAxios.post('/wp-json/wp/v2/item',{
            author: this.props.userInfor.id,
            title: fdata.name,
            status: 'publish',
            content: fdata.desciptions,
            "item-type": fdata.category,
            fields : {
                notes : fdata.notes,
                est_min : fdata.estMin,
                est_max: fdata.estMax,
                url : fdata.url,
                price : fdata.price,
                leadtime : fdata.leadTime,
                gallery : imageIds,
                room : fdata.rooms,
                price_type : fdata.priceType,
                tag: fdata.tag
            }
        }).then(res=>{
            loadingMessage();
            message.success('Done, This item saved to project', 3);
            form.resetFields();
        })
    }

    /*  */
    getImageIds = (dragger) => {
        if( !dragger ) return [];
        const imageIds = dragger.map( item => {
            return item.response.id;
        });
        return imageIds;
    }

    componentDidMount() {
        const author = this.props.userInfor.id;
        Promise.all([
            tokenAxios.get('/wp-json/wp/v2/item-type'),
            tokenAxios.get('/wp-json/wp/v2/project', {
                params: {author}
            }),
            tokenAxios.get('/wp-json/wp/v2/room', {
                params: {author}
            })
        ])
        .then(res => {
            this.setState({
                terms: res[0].data,
                projects:res[1].data,
                rooms: res[2].data,
                isLoading: false
            }, );
        });    
    }

    handleProductTypeChange = (termId, form) => {
        const childTerms = this.state.terms.filter(term => {
            return term.parent === termId;
        });
        this.setState({childTerms});
        form.setFieldsValue({
            category: termId,
        });
    }

    handleProductSubTypeChange = (termId, form) => {
        form.setFieldsValue({
            subCategory: termId,
        });
    }


    render() {
        return(
            <div>
                <PageHeading title='Upload Item'/>
                <Spin spinning={this.state.isLoading}>
                    <CUItem 
                        onFinish={(res, form) => this.handleUploadItem(res, form)}
                        projects={this.state.projects}
                        rooms={this.state.rooms}
                        terms={this.state.terms}
                        onProductTypeChange = {(val, form) => this.handleProductTypeChange(val, form)}
                        onProductSubTypeChange = {(val, form) => this.handleProductSubTypeChange(val, form)}
                        childTerms={this.state.childTerms}
                    />
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        'userInfor' : state.UserReducer.userInfor,
    }
}

export default connect(mapStateToProps, null)(UploadItem);