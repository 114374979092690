import React from 'react';

import PageHeading from '../component/PageHeading/PageHeading';
import PageControls from '../component/PageControls/PageControls';
import Rooms from '../component/Rooms/Rooms';
import FormModal from '../component/Rooms/Modal/FormModal';
import CURoom from '../component/Rooms/Forms/CURoom';
import ModalItem from '../component/Item/ModalItem';
import FilterDraw from '../component/FilterDraw/FilterDraw';

import { connect } from 'react-redux';

import {tokenAxios} from '../axios/Axios';

import * as actionTypes from '../store/Actions';

//Hoc component
import {Spin, Button, message} from 'antd';

import { PlusCircleTwoTone } from '@ant-design/icons';

import {withRouter} from 'react-router-dom';

import withHandleItem from '../hoc/WithHandleItem';


class ManageRooms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            errorMessage : '',
            drawVisible: false
        }
    }
    
    componentDidMount() {
        const projectId = this.props.match.params.id;
        const author = this.props.userInfor.id;
        const per_page = 100;
        
        Promise.all([
            tokenAxios.get('/wp-json/wp/v2/project/'+projectId),
            tokenAxios.get('/wp-json/wp/v2/room', {
                params: {
                    author,
                    projectId
                }
            }),
            tokenAxios.get('/wp-json/wp/v2/item-type', {
                params: {
                    per_page
                }
            }),
        ])
        .then(res => {
            const projects = res[0].data;
            let rooms = res[1].data;
            const itemType = res[2].data;


            this.props.setProjects(projects);
            this.props.setRooms(rooms);
            this.props.setItemType(itemType);

            // Assign Item

            rooms = rooms.map((room) => {
                return room.id
            }).join(',');
            tokenAxios.get('/wp-json/wp/v2/item/',{
                params: {author, 
                per_page: 100,
                rooms
            }}).then(res => {
                this.props.setItems(res.data);
                this.setState({isLoading: false})
            });
        });
    }
    
    showAddRoomForm = () => {
        this.props.setModalFormstate({
            visible : true,
            title : 'Add Room',
            desc : 'Create Custom Room Names to Manage as Needed',
            type: 'add-room',
            confirmLoading: false,
        });
        this.props.setModalFormComponent(<CURoom />)
    }

    cancelFormModal = () => {
        this.props.setModalFormstate({...this.props.modalFormState, visible: false}) 
    }

    handleCancelItemModal = () => {
        this.props.setModalItemState({
            ...this.props.itemModal,
            visible: false
        })
    }

    handleForm = (form) => {
        const formState = this.props.modalFormState;

        form.validateFields().then(values => {
            const formMessage = message.loading('Is Processing', 0);
            if(formState.type === 'add-room') {
                this.handleAddRoom(values, formMessage);
            } else if(formState.type === 'edit-room') {
                this.handleEditRoom(values, formMessage);
            } else if(formState.type === 'assign-room') {
                this.props.handleAssignRoom(values, formMessage);
            } else if(formState.type === 'upload-image') {
                this.props.handleUploadImage(values, formMessage);
            } else if(formState.type === 'edit-details'){
                this.props.handleEditDetails(values, formMessage);
            }
        });
    }

    
    handleAddRoom = (values, formMessage) => {
        console.log(this.props.projects.id)
        tokenAxios.post('/wp-json/wp/v2/room/',{
            author: this.props.userInfor.id,
            title: values.title,
            content: values.desc,
            status: 'publish',
            fields: {
                'select_room': "customRoom",
                'custom_room_type' : values.title,
                'project': this.props.projects.id
            }
        }).then(res => {
            this.props.addRoom(res.data);
            formMessage();
            message.success('Done', 3);
            this.cancelFormModal();
        });
    }

    handleEditRoom = (values, formMessage) => {
        const id = values.id
        tokenAxios.post('/wp-json/wp/v2/room/'+id,{
            content: values.desc,
            title: values.title,
            fields: {
                'custom_room_type' : values.title,
            }
        }).then(res => {
            this.props.updateRoom(res.data);
            formMessage();
            message.success('Done', 3);
            this.cancelFormModal();
        });
    }

    showDrawFilter = () => {
        this.setState({
            drawVisible: true
        });
    }

    closeDrawFilter = () => {
        this.setState({
            drawVisible: false
        });        
    }

    setPageLoading = () => {
        this.setState({
            isLoading: true,
        })
    }

    setPageUnLoad = () => {
        this.setState({
            isLoading: false,
        })
    }
    
    

    handleFilterChange = (values, allValues) => {

        const author = this.props.userInfor.id;

        const rooms = this.props.rooms.map((room) => {
            return room.id
        }).join(',');

        let params = {
            author,
            rooms,
            per_page: 100,
        };

        if( allValues.category ) {
            params['item-type'] = allValues.category[allValues.category.length-1]
        }

        if( allValues.rangePrice ) {
            params['minPrice'] = allValues.rangePrice[0];
            params['maxPrice'] = allValues.rangePrice[1];
        }

        if( allValues.leadTime ) {
            params['leadTime'] = allValues.leadTime
        }


        tokenAxios.get('/wp-json/wp/v2/item/',{params}).then(res => {
            this.props.setItems(res.data);
        });
    }

 

    render() {
        return(
            <div>
                <Spin tip="Loading Rooms..." spinning={this.state.isLoading}>
                    <PageHeading title={this.props.projects.title}/>
                    <PageControls>
                        <Button onClick={this.showAddRoomForm} type="primary" icon={<PlusCircleTwoTone />}>Add Rooms</Button>
                        <Button style={{'marginLeft':'15px'}} onClick={this.showDrawFilter} type="primary" icon={<PlusCircleTwoTone />}>Filter</Button>
                    </PageControls>
                    <Rooms rooms={this.props.rooms} project={this.props.projects} />
                    <FormModal 
                        handleForm={(form) => this.handleForm(form)} 
                        cancelModal={this.cancelFormModal} 
                        modalFormState={this.props.modalFormState}
                        formComponent={this.props.modalFormComponent}
                    />
                    <ModalItem item={this.props.itemModal.currentItem} visible={this.props.itemModal.visible} cancelModal={this.handleCancelItemModal}/>
                    <FilterDraw 
                        placement='right' 
                        drawVisible={this.state.drawVisible} 
                        close={this.closeDrawFilter}
                        itemType={this.props.itemType}
                        onPageLoading={this.setPageLoading}
                        onPageUnload={this.setPageUnLoad}
                    />
                </Spin>
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        addRoom: (room) => {dispatch({type: actionTypes.ADD_ROOM, room})},
        updateRoom: (room) => {dispatch({type: actionTypes.UPDATE_ROOM, room})},
    }
};

export default withHandleItem(withRouter(connect(null, mapDispatchToProps)(ManageRooms)));