import React from 'react';
import {Checkbox} from 'antd';
import './RoomCheckBox.css'

class RoomRadios extends React.Component {
    render() {
        const rooms = this.props.rooms.filter(room => {
            return room.acf.project === this.props.projectId
        });

        if( rooms.length === 0 ) return null;
        return (
            <div className='room-checklist'>
                <h4>{this.props.projectTitle}</h4>
                {rooms.map(room => {
                    let roomTitle = room.acf.select_room === 'roomType' ? room.acf.room_type : room.acf.custom_room_type;
                    return (<Checkbox key={room.id} value={room.id}> {roomTitle} </Checkbox>)
                })}                               
            </div>
        )
    }
}

export default RoomRadios;