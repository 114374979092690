import React from 'react';
import ItemDetails from './ItemDetails';
import {Modal} from 'antd';

class ModalItem extends React.Component {
    render() {

        return(
            <Modal
                title={this.props.item.title}
                visible={this.props.visible}
                onCancel={this.props.cancelModal}
                centered='true'
                footer={null}
            >
                <ItemDetails item={this.props.item} />
            </Modal>            
        )
    }
}

export default ModalItem;
