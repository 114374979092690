import React from 'react';
import './Room.css';
import { Row, Col, message } from "antd";
import {tokenAxios} from '../../../axios/Axios';

import Item from '../../Item/Item';
import RoomHeader from './RoomHeader/RoomHeader';

import AssignRooms from '../../Forms/Item/AssignRooms/AssignRooms';
import UploadImages from '../../Forms/Item/UploadImages/UploadImages';
import EditItemDetails from '../../Forms/Item/EditItemDetails/EditItemDetails';

import withHandleItem from '../../../hoc/WithHandleItem';


class Room extends React.Component {

    showModalItem = (item) => {
        this.props.setModalItemState({...this.props.itemModal, visible: true, currentItem:item})
    }
    
    updateItemRooms = (item, roomId) => {
        const loadingMessage = message.loading('Removing...', 0);
        const roomAssigned = item.acf.room;
        const newRoom = roomAssigned.filter(room =>{
            return room !== roomId
        });
        tokenAxios.put('/wp-json/wp/v2/item/'+item.id,{
            "fields" : {
                "room": newRoom
            }                
        })
        .then(res => {
            this.props.updateItem('room', res.data);
            loadingMessage();
            message.success('Removed', 3);
        });
    }

    assignRooms = (item) => {
        this.props.setModalFormstate({
            visible : true,
            title : 'Assign Room',
            desc : 'Assign Item to Rooms',
            type: 'assign-room',
            confirmLoading: false,
            args: {
                id: item.id
            }
        });
        this.props.setModalFormComponent(<AssignRooms projects={this.props.projects} rooms={this.props.rooms} item={item}/>);
    }

    uploadImage = (item) => {
        this.props.setModalFormstate({
            visible : true,
            title : 'Upload Image',
            desc : 'Upload Image to image',
            type: 'upload-image',
            confirmLoading: false,
            args: {
                id: item.id
            }
        });
        this.props.setModalFormComponent(<UploadImages item={item}/>);
    }

    editDetails = (item) => {
        this.props.setModalFormstate({
            visible : true,
            title : 'Edit Details',
            desc : 'Edit details',
            type: 'edit-details',
            confirmLoading: false,
            args: {
                id: item.id
            }
        });
        
        this.props.setModalFormComponent(<EditItemDetails item={item} itemType={this.props.itemType} />);
    }

    render() {

        /* Get Current Item In Room */
        const roomId = this.props.roomId;
        let items = this.props.items ? this.props.items : [];
        items = this.props.items.filter(item => {
            let result = false;
            if(item.acf.room) {
                result = item.acf.room.includes(roomId)
            }
            return result;
        });


        const roomTitle = this.props.data.acf.select_room === 'roomType' ? this.props.data.acf.room_type : this.props.data.acf.custom_room_type;
        const desc = this.props.data.content.rendered.replace(/(<([^>]+)>)/ig,"");

        return(
            <div className='room'>
                <RoomHeader title={roomTitle} desc={desc} id={roomId} />
                <Row gutter={[24, 24]} justify='center'>
                    {items.map(item => {
                        return(
                            <Col key={item.id} xs={12} md={6} xxl={4} ><Item 
                            showModalItem={(id) => this.showModalItem(id)} 
                            key={item.id} 
                            data={item} 
                            updateItemRooms={(item) => this.updateItemRooms(item, roomId)}
                            assignRooms={() => this.assignRooms(item)}
                            uploadImage={() => this.uploadImage(item)}
                            editDetails={() => this.editDetails(item)}
                            /></Col>
                        );
                    })}
                </Row>
            </div>
        )
    }
}


export default withHandleItem(Room);