import React from "react";
import { Row, Col, Form } from "antd";
import UploadDragger from '../../FormElement/UploadDragger/UploadDragger';
class UploadImages extends React.PureComponent {
	render() {
		this.props.form.resetFields();
		return (
			<Form form={this.props.form} layout="vertical">
				<Row gutter={[12, 12]}>
					<Col xs={24}>
                        <UploadDragger item={this.props.item}/>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default UploadImages;
