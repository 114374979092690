import React from 'react';
import './PageHeading.css';

class PageHeading extends React.Component {
    render(){
        return(
            <h1 className='page-heading'>{this.props.title}</h1>
        )
    }
}

export default PageHeading;