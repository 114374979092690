import React from "react";
import {
	Form,
	Row,
	Col,
	Checkbox,
} from "antd";

import RoomRadios from '../../FormElement/RoomCheckBox/RoomCheckBox';


class AssignRooms extends React.PureComponent {
	// Get Room 
	render() {
		// Get Room in project
		/*
        let currentRooms = this.props.rooms.filter(room => {
            return room.acf.project === this.props.projects[0].id
        }).map(room => {
            return room.id
        });

        // Get Item Room in project
        const itemRooms = this.props.item.acf.room.filter(itemRoom => {
            return currentRooms.includes(itemRoom);
		});
		
		*/
		
		this.props.form.setFieldsValue({rooms: this.props.item.acf.room})

		const projects = Array.isArray(this.props.projects) ? this.props.projects : [this.props.projects]

		return (
			<div>
				<Form
					form={this.props.form}
					layout="vertical"
                    >
                    
					<Row gutter={[12, 12]}>
						{/* Save To Project */}
						<Col md={12}>
							<Form.Item name="rooms" label="Save To">
								<Checkbox.Group>
									{projects.map((project) => {
										return (
											<RoomRadios
												key={project.id}
												rooms={this.props.rooms}
												projectId={project.id}
												projectTitle={project.title}
											/>
										);
									})}
								</Checkbox.Group>
							</Form.Item>

						</Col>
					</Row>
				</Form>
			</div>
		);
	}
}

export default AssignRooms;
