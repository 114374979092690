import React from 'react';
import { Row, Col, message } from "antd";
import {tokenAxios} from '../../axios/Axios';

import Item from './Item';

import AssignRooms from '../Forms/Item/AssignRooms/AssignRooms';
import UploadImages from '../Forms/Item/UploadImages/UploadImages';
import EditItemDetails from '../Forms/Item/EditItemDetails/EditItemDetails';

import withHandleItem from '../../hoc/WithHandleItem';



class ItemListing extends React.PureComponent {

    updateItemRooms = (item, roomId) => {
        const loadingMessage = message.loading('Removing...', 0);
        const roomAssigned = item.acf.room;
        const newRoom = roomAssigned.filter(room =>{
            return room !== roomId
        });
        tokenAxios.put('/wp-json/wp/v2/item/'+item.id,{
            "fields" : {
                "room": newRoom
            }                
        })
        .then(res => {
            this.props.updateItem('room', res.data);
            loadingMessage();
            message.success('Removed', 3);
        });
    }

    assignRooms = (item) => {
        this.props.setModalFormstate({
            visible : true,
            title : 'Assign Room',
            desc : 'Assign Item to Rooms',
            type: 'assign-room',
            confirmLoading: false,
            args: {
                id: item.id
            }
        });

        this.props.setModalFormComponent(<AssignRooms projects={this.props.projects} rooms={this.props.rooms} item={item}/>);
    }

    uploadImage = (item) => {
        this.props.setModalFormstate({
            visible : true,
            title : 'Upload Image',
            desc : 'Upload Image to image',
            type: 'upload-image',
            confirmLoading: false,
            args: {
                id: item.id
            }
        });

        this.props.setModalFormComponent(<UploadImages item={item}/>);
    }

    editDetails = (item) => {
        this.props.setModalFormstate({
            visible : true,
            title : 'Edit Details',
            desc : 'Edit details',
            type: 'edit-details',
            confirmLoading: false,
            args: {
                id: item.id
            }
        });
        
        this.props.setModalFormComponent(<EditItemDetails item={item} itemType={this.props.itemType} />);
    }

    render() {

        const items = this.props.items;
        return(
            <div className='item-listing'>
                <Row gutter={[24, 24]} justify='center'>
                    {items.map(item => {
                        return(
                            <Col key={item.id} xs={12} md={6} xxl={4} ><Item 
                            showModalItem={(id) => this.props.showModalItem(id)} 
                            key={item.id} 
                            data={item}
                            delete={this.props.delete}
 
                            updateItemRooms={(item) => this.updateItemRooms(item)}
                            assignRooms={() => this.assignRooms(item)}
                            uploadImage={() => this.uploadImage(item)}
                            editDetails={() => this.editDetails(item)}
                            deleteItem={() => this.props.deleteItem(item)}
                            /></Col>
                        );
                    })}
                </Row>
            </div>
        )
    }
}


export default withHandleItem(ItemListing);