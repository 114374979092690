import React from 'react';
import {Modal} from 'antd';
import antdForm from '../../../hoc/AntdForm';

class FormModal extends React.Component {
    render() {
        const form = this.props.form ? this.props.form : {};
        return(
            <Modal
                title={this.props.modalFormState.title}
                visible={this.props.modalFormState.visible}
                onOk={() => {this.props.handleForm(form)}}
                confirmLoading={this.props.modalFormState.confirmLoading}
                onCancel={this.props.cancelModal}
                centered='true'
            >
                  {React.cloneElement(this.props.formComponent, {form: form})}
            </Modal>
        )
    }
}

export default antdForm(FormModal);