import React from 'react';

import PageHeading from '../../component/PageHeading/PageHeading';
import PageControls from '../../component/PageControls/PageControls';
import ItemListing from '../../component/Item/ItemListing';
import FormModal from '../../component/Rooms/Modal/FormModal';
import ModalItem from '../../component/Item/ModalItem';
import withHandleItem from '../../hoc/WithHandleItem';
import {withRouter} from 'react-router-dom';
import FilterDraw from '../../component/FilterDraw/FilterDraw';


import {tokenAxios} from '../../axios/Axios';


//Hoc component
import {Spin, Button, message} from 'antd';

import { PlusCircleTwoTone } from '@ant-design/icons';

class LibrarySearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            errorMessage : '',
            title : '',
            drawVisible: false
        }
    }
    
    componentDidMount() {
        const author = this.props.userInfor.id;
        let params = new URLSearchParams(this.props.location.search);
        const per_page = 100;

        Promise.all([
            tokenAxios.get('/wp-json/wp/v2/project/', {params: {author}}),
            tokenAxios.get('/wp-json/wp/v2/room', {params: {author}}),
            tokenAxios.get('/wp-json/wp/v2/item/',{
                params: {
                    author,
                    'item-type': params.get('item-type'),
                    per_page
                },
            }),
            tokenAxios.get('/wp-json/wp/v2/item-type', {
                params: {
                    per_page
                }
            }),
        ])
        .then(res => {
            const projects = res[0].data;
            const rooms = res[1].data;
            const item = res[2].data;
            const itemType = res[3].data;


            this.props.setProjects(projects);
            this.props.setRooms(rooms);
            this.props.setItems(item);
            this.props.setItemType(itemType);

            //set Title

            this.setTitle(params)

            this.setState({isLoading: false})
        });
    }

    handleForm = (form) => {
        const formState = this.props.modalFormState;
        form.validateFields().then(values => {
            const formMessage = message.loading('Is Processing', 0);
            if(formState.type === 'assign-room') {
                this.props.handleAssignRoom(values, formMessage);
            } else if(formState.type === 'upload-image') {
                this.props.handleUploadImage(values, formMessage);
            } else if(formState.type === 'edit-details'){
                this.props.handleEditDetails(values, formMessage);
            }
        });
    }

    setTitle(params) {
        const currentItemTypeId = params.get('item-type');
        const itemType = this.props.itemType.filter(item => {
            return item.id === parseInt(currentItemTypeId);
        });
        
        const title = itemType ? itemType[0]['name'] : '';

        this.setState({
            'title': title
        });
    }

    showDrawFilter = () => {
        this.setState({
            drawVisible: true
        });
    }

    closeDrawFilter = () => {
        this.setState({
            drawVisible: false
        });        
    }

    setPageLoading = () => {
        this.setState({
            isLoading: true,
        })
    }

    setPageUnLoad = () => {
        this.setState({
            isLoading: false,
        })
    }    

    render() {
        return(
            <div>
                <Spin tip="Loading Item..." spinning={this.state.isLoading}>
                    <PageHeading title={`Item Library: ${this.state.title} `} />
                    <PageControls>
                        <Button onClick={this.showDrawFilter} type="primary" icon={<PlusCircleTwoTone />}>Filter</Button>
                    </PageControls>
                    <ItemListing delete="permanently-delete" />
                    <FormModal 
                        handleForm={(form) => this.handleForm(form)} 
                        cancelModal={this.props.cancelFormModal} 
                        modalFormState={this.props.modalFormState}
                        formComponent={this.props.modalFormComponent}
                    />
                    <ModalItem item={this.props.itemModal.currentItem} visible={this.props.itemModal.visible} cancelModal={() => this.props.cancelItemModal()}/>
                    <FilterDraw 
                        placement='right' 
                        drawVisible={this.state.drawVisible} 
                        close={this.closeDrawFilter}
                        itemType={this.props.itemType}
                        onPageLoading={this.setPageLoading}
                        onPageUnload={this.setPageUnLoad}
                    />               
                </Spin>
            </div>
        )
    }
}


export default withRouter(withHandleItem(LibrarySearch));