import React from "react";
import { Cascader, Form } from "antd";

class ItemTypeSelect extends React.Component {
  
  render() {
    const parentType = this.props.itemType.filter(item => {
      return item.parent === 0; 
    });

    const child = this.props.itemType.filter(item => {
      return item.parent !== 0; 
    });

    const itemTypeOption = parentType.map(item => {
        return {
          value: item.id,
          label: item.name,
          children: child.filter(childItem => {
            return childItem.parent === item.id
          }).map(item => {
            return {
              value: item.id,
              label: item.name,
            }
          })
        }
    });
    
    return (
      <Form.Item
        name="category"
        label="Product Type"
        hasFeedback
        required = {true}
        rules={[
          {
            required: this.props.required,
            message: "Please select your type!",
          },
        ]}
      >
        <Cascader         
            expandTrigger="hover"
            options={itemTypeOption} 
            placeholder="Please select"
            changeOnSelect
            //onChange = {this.itemTypeChange}
        />
      </Form.Item>
    );
  }
}

export default ItemTypeSelect;
