import * as actionTypes from './Actions';
import produce from 'immer';


const initialState = {
    projects : [],
    rooms : [],
    items : [],
    itemType : [],
    modalFormState: {
        visible : false,
        title : '',
        desc : '',
        type: '',
        confirmLoading: false,
        args: {}
    },
    modalFormComponent: '',
    itemModal: {
        visible: false,
        currentItem: '',
    },
    
}

const singleProjectReducer = (state = initialState, actions) => {
    let rooms = [];
    //let items = [];
    switch(actions.type) {
        case actionTypes.SET_PROJECT:
            return {
                ...state,
                projects : actions.projects
            }
        case actionTypes.SET_ROOMS: 
            return {
                ...state,
                rooms: actions.rooms
            }
        case actionTypes.SET_ITEMS: 
            return {
                ...state,
                items: actions.items
            }
        case actionTypes.SET_ITEM_TYPE:
            return produce(state, draft => {
                draft.itemType = actions.itemType
            });
        case actionTypes.ADD_ROOM:
            rooms = [...state.rooms];
            rooms.unshift(actions.room);
            return {
                ...state,
                rooms    
            }
        case actionTypes.SET_MODAL_STATE:
            return {
                ...state,
                modalFormState: actions.modalState
            }
        case actionTypes.UPDATE_ROOM:
            rooms = state.rooms.map(room => {
                if( room.id === actions.room.id ) {
                    return actions.room;
                }
                return room;
            });
            return {
                ...state,
                rooms
            }
        case actionTypes.DELETE_ROOM:
            return {
                ...state,
                rooms: state.rooms.filter(room => room.id !== actions.id)
            }
        case actionTypes.SET_MODAL_ITEM_STATE:
            return produce(state, draft=> {
                draft.itemModal = actions.modalItemState
            });
        case actionTypes.SET_MODAL_FORM_COMPONENT: 
            return {
                ...state,
                modalFormComponent: actions.component
            }
        case actionTypes.UPDATE_ITEM:
            return produce(state, draft => {
                const index = draft.items.findIndex(item => {
                    return item.id === actions.item.id;
                });

                if( actions.which === 'all' ) {
                    draft.items[index] = actions.item
                } else {
                    draft.items[index].acf[actions.which] = actions.item.acf[actions.which];
                }
        });
        case actionTypes.DELETE_ITEM:
            return produce(state, draft => {
                draft.items = draft.items.filter(item => item.id !== actions.itemId)
        });

        default: return state;
    }
}

export default singleProjectReducer;