import React from 'react';
import {Carousel} from "antd";

class ItemGallery extends React.Component {
    
    render() {
        const gallery = this.props.gallery ? this.props.gallery : [];
        return(
            <Carousel  >
                {gallery.map(img => {
                //if( img === 'undefined' ) return false;
                console.log(img);
                return (<img key={img.id} alt={img.id} 
                            onClick={
                                typeof this.props.showModalItem === "function" === true ? (data) => this.props.showModalItem(this.props.data) : false
                            }
                            src={
                                this.props.imageSize === 'slide' ? img.sizes.woocommerce_thumbnail : img.url
                            } 
                            />)
                })}
            </Carousel>
        )
    }
}

export default ItemGallery;