import React from 'react';

// import Andtd
import { Form, Input, Button, Checkbox, Spin, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

class LoginForm extends React.Component {
 
    render() {
        return(
            <div className='login-form-wrap'>
                <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={(value) => {this.props.onSubmitLoginForm(value)}}
                >
                <Form.Item
                    name="username"
                    rules={[
                    {
                        required: true,
                        message: "Please input your Username!",
                    },
                    ]}
                >
                    <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                    {
                        required: true,
                        message: "Please input your Password!",
                    },
                    ]}
                >
                    <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    />
                </Form.Item>
                
                {this.props.isLogging ? (<div style={{textAlign:'center'}}><Spin/></div>) : ''}


                {
                    this.props.errorMessage !== '' ?
                    <Alert
                    message="Error"
                    description={this.props.errorMessage}
                    type="error"
                    showIcon
                    /> : ''
                }

                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                    Log in
                    </Button>
                </Form.Item>
                </Form>
            </div>            
        )
    }
}

export default LoginForm;