import React from "react";
import { Upload, Form } from "antd";
import { tokenAxios } from "../../../../axios/Axios";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {message} from 'antd';

import "./Uploader.css";

class Uploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            imageUrl: ''
        };
    }

    customRequest = (options) => {
        this.setState({loading:true});
        const formMessage = message.loading('Is Updating, Please wait!', 0);

        const data = new FormData();
        data.append("file", options.file);
        const config = {
            headers: {
                "content-type":
                    "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
            },
        };
        tokenAxios
            .post("/wp-json/wp/v2/media", data, config)
            .then((res) => {
                options.onSuccess(res.data, options.file);
                formMessage();
                message.success('Updated new image!', 3);
                this.setState({
                    loading:false,
                    imageUrl:res.data.source_url,
                });
            })
            .catch((err) => {});
    };

    uploadField = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    render() {
        const imageUrl = this.state.imageUrl ? this.state.imageUrl : this.props.imageUrl;
        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div className="clearfix">
                <Form.Item label={this.props.label}>
                    <Form.Item
                        name={this.props.name}
                        getValueFromEvent={(e) => this.uploadField(e)}
                        wrapperCol={{
                            'xs':24,
                        }}
                    >
                        <Upload
                            customRequest={this.customRequest}
                            className="designer-banner"
                            listType="picture-card"
                            showUploadList={false}

                        >
                            {imageUrl ? (
                                <div>
                                    <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{ width: "100%" }}
                                    />
                                    {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                                </div>

                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Form.Item>
                </Form.Item>

            </div>
        );
    }
}


export default Uploader;
