import React from 'react';
import { Form, Input} from 'antd';

class CURoom extends React.PureComponent {
    render() {
        const form = this.props.form;

        this.props.form.setFieldsValue({
            title:this.props.title, 
            desc:this.props.desc,
            id:this.props.id
        });

        return(
            <Form form={form} labelAlign='left' labelCol={{span: 5}}>
                <Form.Item 
                    name="title"
                    label="Title"
                    rules={[
                    {
                        required: true,
                        message: 'Please input the title of collection!',
                    },
                    ]}
                >
                <Input />
                </Form.Item>
                <Form.Item label="Description" name="desc">
                <Input/>
                </Form.Item>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
            </Form> 
        )
    }
}

export default CURoom;