import React from 'react';
import LoggedMenu from './LoggedMenu/LoggedMenu';
import LogOutMenu from './LogOutMenu/LogOutMenu';

import { connect } from 'react-redux';

import './TopMenu.css';
import {Row, Col} from 'antd';

import mainLogo from './image/ds-logo.jpg';


class TopMenu extends React.Component {

    handleLogOut = () => {
        localStorage.setItem('token', '');
        this.props.setToken('');
        this.props.setAuthenication(false);
        this.props.setUserInfor({});
    }

    render() {
        return(
            <Row>
                <Col span={4} md={4}><a href={process.env.REACT_APP_API_URL}><img src={mainLogo} alt='main-logo' /></a></Col>
                {this.props.isAuthenication ? <LoggedMenu logOut={this.handleLogOut} userInfor={this.props.userInfor} /> : <LogOutMenu />}
            </Row>
        )
    }
}

const mapStateToProps = state => {
    return {
        'userInfor' : state.UserReducer.userInfor,
        'isAuthenication' : state.UserReducer.isAuthenication,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setToken: (token) => {dispatch({type: 'SET_USER_TOKEN', token})},
        setAuthenication: (authn) => dispatch({type: 'SET_AUTHENNICATION', authn}),
        setUserInfor: (userInfor) => dispatch({type: 'SET_USER_INFOR', userInfor})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);