import * as actionTypes from './Actions';

const initialState = {
    projects : []
}

const projectReducer = (state = initialState, actions) => {
    switch(actions.type) {
        case actionTypes.LOAD_PROJECT:
            return {
                ...state,
                projects : actions.projects
        }
        case actionTypes.ADD_PROJECT:
            const newProjects = [...state.projects];
            newProjects.push(actions.project);
            return {
                ...state,
                projects:newProjects    
            }
        case actionTypes.UPDATE_PROJECT: 
            let projects = [...state.projects];
            projects = projects.map(project => {
                if( project.id === actions.project.id ) {
                    return actions.project;
                }
                return project;
            });
            return {
                ...state,
                projects
            }
        case actionTypes.DELETE_PROJECT:
            return {
                ...state,
                projects: state.projects.filter(project => project.id !== actions.id)
            }
        default: return state;
    }
}

export default projectReducer;