import React from "react";
import { Upload, Form, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import {tokenAxios} from "../../../../axios/Axios"

class UploadDragger extends React.Component {
    customRequest = (options) => {
        const data= new FormData()
        data.append('file', options.file)
        const config= {
            "headers": {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            }
        }
        tokenAxios.post('/wp-json/wp/v2/media', data, config).then((res) => {
            options.onSuccess(res.data, options.file)
        }).catch((err) => {
        })
    }

    uploadField = (e) => {    
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    onRemove(file) {
        const formMessage = message.loading('Is Processing', 0);
        tokenAxios.delete('/wp-json/wp/v2/media/'+file.uid, {
            params: { 
                force: true,
            }
        }).then(res => {
            formMessage();
            message.success('Done, Please press OK to update gallery.', 3);
        });         
    }

	render() {
        let fileList = [];
        if( this.props.item ) {
            const gallery = this.props.item.acf.gallery ? this.props.item.acf.gallery : [];
            fileList = gallery.map(img => {
                return {
                    uid: img.ID,
                    name: img.filename,
                    status: 'done',
                    url: img.url,
                    thumbUrl: img.sizes.woocommerce_thumbnail,
                    response: img
                }
            });
        }


		return (
            <Form.Item label="">
                    <Form.Item
                        name="dragger"
                        getValueFromEvent={(e) => this.uploadField(e)}
                    >
                    <Upload.Dragger
                        customRequest={this.customRequest}
                        multiple={true}
                        progress={{ strokeWidth: 5, showInfo: true }}
                        listType="picture"
                        defaultFileList = {fileList}
                        onRemove={(file) => this.onRemove(file)}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Click or drag file to this area to upload
                        </p>
                        {/*<p className="ant-upload-hint">Support for a single or bulk upload.</p>*/}
                    </Upload.Dragger>
                </Form.Item>
			</Form.Item>
		);
	}
}

export default UploadDragger;
